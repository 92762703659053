.slide-top {
  animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes slide-top {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}