.scale-up-bottom {
  animation: scale-up-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@keyframes scale-up-bottom {
  0% {
    transform: scale(0.5);
    transform-origin: 50% 100%;
  }

  100% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
}